import React from 'react';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import Slide from 'react-reveal/Slide';
import Fade from 'react-reveal/Fade';

import SEO from '../components/seo.js';

import { Item, Menu } from '../styles/styles.js';

const Porcoes = ({ data }) => {
  return (
    <>
      <SEO
        title="Porções"
        keywords={[
          `Porções`,
          `Batata`,
          `Batata com Bacon`,
          `filé de frango`,
          `Porção de Calabresa`,
        ]}
      />
      <Menu className="menu">
        <Slide left cascade>
          <h1 className="menu-title">Porções</h1>
        </Slide>

        <Fade left>
          <div className="item-image">
            <Img
              fluid={data.porcoes.childImageSharp.fluid}
              alt="Porções vários sabores"
            />
          </div>
        </Fade>

        <Item>
          <div className="item-separator">
            <h4 className="item-title">Porção de Batata</h4>

            {/* <p className="item-desc">
            Molho de tomate, queijo, tomate, bacon cebola e azeitona.
          </p> */}

            <div className="item-price-wrapper">
              <h4 className="item-price">25,00</h4>
            </div>
          </div>
        </Item>

        <Item>
          <div className="item-separator">
            <h4 className="item-title">Porção de Batata com Bacon</h4>

            {/* <p className="item-desc">
            Molho de tomate, queijo, tomate, bacon cebola e azeitona.
          </p> */}

            <div className="item-price-wrapper">
              <h4 className="item-price">30,00</h4>
            </div>
          </div>
        </Item>

        <Item>
          <div className="item-separator">
            <h4 className="item-title">Porção de Batata Cremosa</h4>

            <div className="item-price-wrapper">
              <h4 className="item-price">45,00</h4>
            </div>
          </div>
          <p className="item-desc">Batata com mussarela, bacon e catupiry.</p>
        </Item>

        <Item>
          <div className="item-separator">
            <h4 className="item-title">Porção de Calabresa Acebolada</h4>

            <div className="item-price-wrapper">
              <h4 className="item-price">45,00</h4>
            </div>
          </div>
          {/* <p className="item-desc">
            Promoção de porção de calabresa para o mês de setembro
          </p> */}
        </Item>

        <Item>
          <div className="item-separator">
            <h4 className="item-title">Porção de Filé Acebolado</h4>

            {/* <p className="item-desc">
            Molho de tomate, queijo, tomate, bacon cebola e azeitona.
          </p> */}

            <div className="item-price-wrapper">
              <h4 className="item-price">80,00</h4>
            </div>
          </div>
        </Item>

        <Item>
          <div className="item-separator">
            <h4 className="item-title">Porção de Filé de Tilápia</h4>

            {/* <p className="item-desc">
            Molho de tomate, queijo, tomate, bacon cebola e azeitona.
          </p> */}

            <div className="item-price-wrapper">
              <h4 className="item-price">70,00</h4>
            </div>
          </div>
        </Item>

        <Item>
          <div className="item-separator">
            <h4 className="item-title">Porção de Frango</h4>

            <div className="item-price-wrapper">
              <h4 className="item-price">40,00</h4>
            </div>
          </div>
          <p className="item-desc">Coxinha da asa.</p>
        </Item>

        <Item>
          <div className="item-separator">
            <h4 className="item-title">Porção de Frios</h4>

            <div className="item-price-wrapper">
              <h4 className="item-price">60,00</h4>
            </div>
          </div>
          <p className="item-desc">
            Ovo de codorna, azeitona, presunto, palmito, pepino, mussarela e
            salame.
          </p>
        </Item>

        <Item>
          <div className="item-separator">
            <h4 className="item-title">Porção Especial União</h4>

            <div className="item-price-wrapper">
              <h4 className="item-price">95,00</h4>
            </div>
          </div>
          <p className="item-desc">
            Frios, calabresa, batata frita e filé de carne.
          </p>
        </Item>
      </Menu>
    </>
  );
};

export const query = graphql`
  query {
    porcoes: file(relativePath: { eq: "porcoes.png" }) {
      childImageSharp {
        fluid(maxWidth: 350, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;

export default Porcoes;
